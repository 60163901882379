*{
  font-family: Arial, sans-serif;
  box-sizing: border-box;
  margin:0;
  padding:0;
  scroll-behavior: smooth;
}

body{
  background: var(--color-bg);
  min-height: 100vh;
  font-size: 16px;
}

a{
  color: inherit;
  text-decoration: none;
}

.section__padding {
  padding: 4rem 6rem;
}

.section_margin {
  margin: 4rem 6rem;
}

/* Large screen view */
@media screen and (min-width: 1200px) {
  body {
    font-size: 18px;
  }

  .section__padding {
    padding: 4rem 8rem;
  }

  .section__margin {
    margin: 4rem 8rem;
  }
}

/* tablet view */
@media screen and (max-width: 768px) {
  .body {
    font-size: 15px;
  }

  .section__padding{
    padding: 3rem ;
  }

  .section__margin{
    margin: 3rem ;
  }
}

/* mobile view */
@media screen and (max-width: 480px) {
  .body {
    font-size: 14px;
  }

  .section__padding{
    padding: 2rem ;
  }

  .section__margin{
    margin: 2rem ;
  }
}

.App{
  text-align: center;
}

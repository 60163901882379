
.about-container {
    display: flex;
    flex-direction: row;
    background-color: #bebcbc;
    height: 31.25%;
    padding: 3.125rem 8.7rem 3.125rem 8.7rem; /* 50px 20vh 50px 20vh*/
}

.about-img-mobile{
    display: none;
}

.about-text-container {
    display: flex;
    flex-direction: column;
    gap: 1.5625rem;
    text-align: left;
    width: 50%;
    height: auto;
}

.about-title {
    font-size: 1em;
    text-align: left;
}

.about-text {
    font-size: 1.3vw;
    display: flex;
    flex-direction: column;
    gap: 3.125rem;
    width: 80%;
    text-align: start;
}

.about-img-grids-container{
    display: grid; 
    grid-template-columns: repeat(2, 1fr); 
    grid-template-rows: 1fr; 
    gap: 0rem 0.925rem;
    width: 80%;
    height: 100%;
}

.about-img-col-1 {
    width: 100%;
    height: 90%;
    border-radius: 0.9375rem;

}

.about-img-col-1 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.9375rem;   
}

.about-img-col-2 {
    display:grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
    gap: 0px 0px; 
    border-radius: 0.9375rem;
    width: 100%;
    height: 90%;
}

.about-img-row-1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 85%;
    background-color: #282828;
    border-radius: 0.9375rem;
    color: #ffff;
}


.about-img-row-2 {
    width: 100%;
    border-radius: 0.9375rem;
}

.about-img-row-2 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.9375rem;
}

/* Mobile view */
@media screen and (max-width: 850px) {
    .about-container {
        flex-direction: column;
        align-items: center;
        text-align: center;
        height: 70%;
        padding: 2rem 1rem;
        overflow: hidden;
    }
    
    .about-img-mobile{
        display: block;
        width: 80%;
        margin: 1rem auto;
    }
    
    .about-img-mobile img {
        width: 100%;
        height: 35vh;
        object-fit: cover;
        border-radius: 0.9375rem;
    }

    .about-text-container {
        width: 92%;
    }
    
    .about-title {
        text-align:center;
    }
    
    .about-text {
        align-items: center;
        margin: 0 3% 0 11%;
        gap: 1rem;
        background-color: #282828;
        color: #ffff;
        border-radius: 0.9375rem;    
    }
    
    .about-img-row-1 h1 {
        font-size: 13px;
    }

    .about-text p{
        font-size: 3vw;
        letter-spacing: 0.1rem;
        margin: 1rem;
    }

    .about-img-grids-container {
        display:none;
        visibility: hidden;
    }
}
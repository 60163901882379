.service-page-container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.service-header-photo img {
    border-bottom-right-radius: 6rem;
    border-bottom-left-radius: 6rem;
    position:absolute;
    bottom: 70%;
    transform: translate(-50%, 10rem);
    object-fit: cover;
    width: 100%;
}

.service-page-title {
    position: absolute;
    left: 58%;
    bottom: 60%;
    font-size: 1.8rem;
    color: white;
}

.service-page-title h1 {
    text-transform: uppercase;
    text-align: left;
}

.service-page-title p {
    font-size: 0.5vw;
    padding: 5% 0% 0% 8%;
    width: 78%;
    text-transform: uppercase;
    letter-spacing: 0.2vw;
    text-align: center;
}

.service-aim-container {
    height: 100%;
    margin-top: 55vh;
}

.service-aim-title {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.service-aim-title h5{
    font-size: 1vw;
    text-transform: uppercase;
    letter-spacing: 0.2vw;
}

.service-aim-title p {
    font-size: 1vw;
    margin: 2rem 0rem 0rem 0rem;
    width: 50%;
    text-align: justify;
}

.service-data-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    
}

.service-data-title-container {
    display: flex;
    flex-direction: row;
    flex-wrap:wrap;
    margin: 3rem 25%;
    width: 50%;
    background-color: rgb(255, 255, 255);
    border-radius: 1rem;
    box-shadow: black;
    padding: 0.4% 0%;
    align-items: center;
    justify-content: center;

}

.service-data-title {
    display: flex;
    flex-direction: column;
    margin: 0% 0.5%;
}

.service-data-title h5 {
    font-size: 0.9vw;
    border-radius: 15px;
    border: 0.1rem solid transparent;
    padding: 1rem;
    text-transform: uppercase;
    letter-spacing: 0.1vw;
}

.service-data-title-selected {
    font-size: 0.9vw;
    border-radius: 15px;
    border: 0.1rem solid transparent;
    padding: 1rem;
    text-transform: uppercase;
    letter-spacing: 0.1vw;
    border: 0.1rem solid gray;
}

.service-data-text-container {
    align-items: center;
    margin: 0% 18% 5% 18%;
    width: 80%;
    border: 2px solid rgb(255, 255, 255);
    border-radius: 15px;

}

.service-data-text-img {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    margin: 0% 0% 0% 0%;
    justify-content: space-around;
}

.service-data-txt {
    width: 50%;
}

.service-data-img {
    width: 50%;
    margin: 0 2% 0 2%;
}
.service-data-txt p{
    font-size: 1vw;
    word-spacing: 0.3vw;
    margin: 0 12% 0 2%;
    text-align: justify;
}

.service-data-text-img img {
    width: 90%;
    border-radius: 15px;
    object-fit: cover;
    transition: 0.01s all ease-in-out;
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.5); 
}


@media screen and (max-width: 950px) {
    .service-header-photo img {
         bottom: 73%;
         border-radius: 50px;
         width: 100%;
         height: 80%;
     }

     .service-page-title {
        left: 50%;
        transform: translate(-50%);
        bottom: 63%;
        font-size: 2vw;
    }

    .service-page-title h1 {
        text-align: center;
    }

    .service-page-title p {
        font-size: 1vw;
        width: 100%;
    }

    .service-aim-container{
        margin-top: 50vh;
    }

    .service-aim-title {
        width: 100%;
    }

    .service-aim-title h5{
        font-size: 1.8vw;
    }

    .service-aim-title p{
        font-size: 1.6vw;
    }


    .service-data-title-container {
        /* flex-wrap: nowrap; */
        backdrop-filter: blur(10px);
        background-color: rgba(93, 98, 106, 0.101);
        border: 1px solid rgba(255, 255, 255, 0.258);
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      
        width: 80%;
    }

    .service-data-title h5 {
        font-size: 1.2vw;
    }

    .service-data-title-selected {
        font-size: 1.5vw;
    }

    .service-data-txt p{
        font-size: 1.3vw;
    }

}
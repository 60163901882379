.contact-page-container {
  display: flex;
  flex-direction: column;
  height:80%;
}


.contact-header-photo img {
  position: absolute;
  bottom: 70%;
  transform: translate(-50%, 10rem);
  border-bottom-right-radius: 6rem;
  border-bottom-left-radius: 6rem;
  object-fit: cover;
  width: 100%;
}

.contact-page-title {
  position: absolute;
  left: 62%;
  bottom: 60%;
  font-size: 1.5rem;
  color: white;
  text-transform: uppercase;
}

.contact-form {
  position: relative;
  z-index: 1;
  padding-top: 28%;
}

/* Tablet view */
@media screen and (max-width: 1290px) {
  .contact-page-title {
    left: 60%;
    bottom: 63%;
    font-size: 0.8rem;
  }

  .contact-header-photo img {
    bottom: 75%;
    border-radius: 32px;
    width: 100%;
    height: 90%;
  }

  .contact-form {
    position: relative;
    z-index: 1;
    padding-top: 48vh;
  }
  

}

/* Mobile view */
@media screen and (max-width: 480px) {

  .contact-form h1 {
    visibility: hidden;
  }
  
  .contact-header-photo img {
    position: absolute;
    bottom: 73%;
    border-radius: 15px;
    width: 100%;
    height: 80%;
  }
  
  .contact-page-title {
    font-size: 2vw;
    left: 50%;
    transform: translate(-50%);
    bottom: 63%;
  }

}
.hero {
    background-image: url('../../assets/car_hero_1.jpg');
    background-position: center;
    background-size: cover; 
    height: 45rem;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    
  }
  
.hero-title {
  text-align: left;
  padding: 0px 9.8%;
  margin-bottom: 1.25rem; /* Adjust space between title and stats */
}

.hero-title h1 {
  font-size: 7em; /* Large text for the main title */
  margin: 0;
}

.hero-title button {
  background-color: #fff; /* White background for the button */
  color: #000; /* Black text for the button */
  border: none;
  border-radius: 3.125rem;
  font-weight: 400;
  padding: 0.625rem 1.25rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}


.hero-title button:hover {
  background-color: #ddd;
}

.hero-stats {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 50%;
  position: absolute;
  bottom:  0.625rem;
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.101);
  border: 1px solid rgba(255, 255, 255, 0.258);
  border-radius: 50px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 15px;

}

.hero-stats h5 {
  margin: 0.125rem 0 0.125rem 0.3125rem; 
}

.hero-stats p {
  font-size: 1em;
  margin: 0; /* Remove default margin */
  color: #818380;
}

.hero-stats button {
  position: relative;
  left: 6rem;
  background-color: white;
  border: 2px solid white;
  border-radius: 3.125rem;
  padding:  0.625rem;
  color: black;
  cursor: pointer;
}


.hero-stats button::before {
  content: '→';
  font-size: 2em;
}


/* Large screen view */
/* @media screen and (min-width: 1800px) {
  .hero {
    height: 50rem;
  }

  .hero-title {
    text-align: center;
    padding: 0px 12%;
  }

  .hero-title h1 {
    font-size: 6rem;
  }

  .hero-stats {
    width: 50%;
  }

  .hero-stats h5 {
    font-size: 1em;
    margin: 0.125rem 0 0.1875rem 0.3125rem; 
  }  

  .hero-stats button { 
    padding: 1rem;
    left: 2.9rem;
  }
}
 */

/* Tablet view */
@media screen and (max-width: 914px) {
  .hero {
    height: 38rem;
    background-position: right;
  }

  .hero-title {
    text-align: center;
    padding: 0px 12%;
    margin-bottom: 0.2rem;
  }
  .hero-title h1 {
    font-size: 3.8rem;
  }

  .hero-stats {
    width: 70%;
  }

  .hero-stats h5 {
    font-size: 0.7rem;
  }

  .hero-stats p {
    padding-top: 0.2rem;
    font-size: 0.7rem;
  }

  .hero-stats button::before {
    font-size: 1.2em;
  }
  .hero-stats button{
    /* left: 0.9rem; */
  }
  
}

/* Mobile view */
@media screen and (max-width: 480px) {
  .hero {
      height: 31.25rem; /* 500px equivalent */
      background-position: center;
  }

  
  .hero-title h1 {
    font-size: 2rem; /* Smaller font size for mobile */
  }

  .hero-title button {
    margin-top: 12px;
    font-size: 0.7rem;
  }
  
  .hero-stats {
      width: 90%;
  }

  .hero-stats h5 {
    font-size: 8px;
  }

  .hero-stats p {
    padding-top: 0.2rem;
    font-size: 0.6rem;
  }

  .hero-stats button::before {
    font-size: 1em;
  }
  .hero-stats button{
    left: 2rem;
  }
}

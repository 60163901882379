.navbar-container{
  position: absolute;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  display: flex;
  flex-direction:row;
  justify-content: space-between;
  align-items: center;
  border-radius: 3rem;
  /* margin: 1.6% 0%; */
  padding: 0.5% 3.1%;
  max-width: 100%;
  height: 11%;
  z-index: 1000;
  /* visibility: visible; */
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}
.mobile-menu-container{
  display: none;
}

.navbar-logo {
  margin: 0 50vw 0 0;
}

.navbar-logo img {
  width: 5rem; 
  height: auto;
}

.navbar-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.25rem; 
}

.navbar-menu p {
  margin: 0;
  padding: 0;
  transition: transform 0.3s ease;
  white-space: nowrap; /* Prevent text wrapping */
  font-size: 1.25vw;
  /* margin: 0 0.5rem; */
}

.navbar-menu a {
  display: inline-block;
  position: relative;
  color: #818380;
  text-decoration: none;
  padding: 0.625rem;
  transition: background-color 0.3s ease;
  border-radius: 3.125rem;
}

.navbar-menu p.selected a {
  color: #000000; /* Color for selected item */
  font-weight: bold;
  transform: scale(1.0);
}

.navbar-menu p.selected a::after {
  position: absolute;
  display: block;
  content: '';
  width: 50%; /* Initially no width */
  height: 0.1875rem; /* Same height as desired border */
  background-color: #818380; /* Border color */
  bottom: 0.125rem; /* Position it right below the link */
  left: 50%; /* Center it initially */
  transform: translate(-50%);

}

.navbar-menu a::after {
  position: absolute;
  display: block;
  content: '';
  width: 0; /* Initially no width */
  height: 0.1875rem; /* Same height as your desired border */
  background-color: #818380; /* Border color */
  bottom: -0.125rem; /* Position it right below the link */
  left: 50%; /* Center it initially */
  transition: background-color 0.3s ease, width 0.3s ease, left 0.3s ease; /* Animate width and horizontal position */
}

.navbar-menu a:hover::after {
  width: 50%; /* Change to desired width on hover */
  left: 25%; /* Adjust left position to keep it centered */
}



 /* mobile responsiveness */
 @media screen and (max-width: 950px) {
  .navbar-container {
    top: 0;
    left: 0;
    transform: unset;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: rgb(255, 255, 255);
    backdrop-filter: blur(10px);
    /* background-color: rgba(255, 255, 255, 0.101); */
    /* border: 1px solid rgba(255, 255, 255, 0.258); */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: unset;
    padding: 2% 4%;
  }
  .navbar-logo {
    display: none;
  }

  .navbar-menu {
    display: none;
  }

 .mobile-menu-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    /* gap: 21rem; */
  }
  
  .mobile-menu-container img {
    width: 3.125rem; 
    height: 100%;
  } 
  
  .mobile-menu-logo img {
    width: 4rem; 
  } 

  /*
  */
  .mobile-menu {
    position: absolute;
    top: 0;
    right: 0;
    height: 100vh;
    width: 60%;
    display: flex;
    flex-direction: column;
    backdrop-filter: blur(10px);
    background-color: red;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgba(255, 255, 255, 0.258);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    align-items: flex-start;
    justify-content: flex-start;
    padding: 2% 0 0 5%;
  }
  
  .mobile-menu p {
    margin: 0.5rem 0;
    padding: 0;
    transition: transform 0.3s ease;
    white-space: nowrap; /* Prevent text wrapping */
    width: 100%;
    display: flex;
  }
  
  .mobile-menu a {
    display: inline-block;
    position: relative;
    color: #818380;
    text-decoration: none;
    padding: 0.625rem;
    transition: background-color 0.3s ease;
    border-radius: 3.125rem;
  }

  .mobile-menu img {
    width: 2.5rem; 
    height: auto;
  }
}
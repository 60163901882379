.about-page-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-bottom: 5%;
}

.about-header-photo img {
    position: absolute;
    bottom: 70%;
    transform: translate(-50%, 10rem);
    border-bottom-right-radius: 6rem;
    border-bottom-left-radius: 6rem;
    object-fit: cover;
    width: 100%;
}

.about-page-title {
    position: absolute;
    left: 62%;
    bottom: 60%;
    font-size: 1.5vw;
    color: white;
    text-transform: uppercase;
}

.about-page-title h1 {
    text-align: left;
}

.about-page-title p {
    font-size: 0.5vw;
    padding: 5% 0% 0% 8%;
    width: 78%;
    letter-spacing: 0.2vw;
    text-align: center;
}

.about-aim-container {
    height: 100%;
    width: 100%;
    margin-top: 55vh;
}

.about-aim-title {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

}

.about-aim-title h5{
    font-size: 1vw;
    text-transform: uppercase;
    letter-spacing: 0.2vw;
}

.about-aim-title p {
    font-size: 1vw;
    margin: 1rem 0rem 2rem 0rem;
    width: 40%;
    text-align: center;
}

.about-data-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    
}

.about-data-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0% 0% 5% 0%;
}

.about-data-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    margin: 4% 0% 2% 0%;
}

.about-data-title h5 {
    font-size: 0.9vw;
    letter-spacing: 0.1vw;
}

.about-data {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0% 18% 5% 18%;
    gap: 2%;
    justify-content: space-around;
}

.about-data-txt {
    width: 50%;
}

.about-data-img {
    width: 50%;
}

.about-data p {
    font-size: 1vw;
    word-spacing: 0.3vw;
    text-align: justify;
}


.about-data img {
    width: 70%;
    border-radius: 15px;
    object-fit: cover;
    transition: 0.01s all ease-in-out;
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.5);
}

.about-brands-container {
    font-size: 0.5vw;
    letter-spacing: 0.1vw;
    margin: 0% 0% 5% 0%;
}

@media screen and (max-width: 950px) {
   .about-header-photo img {
        bottom: 73%;
        border-radius: 50px;
        width: 100%;
        height: 80%;
    }
    
    .about-page-title {
        left: 50%;
        transform: translate(-50%);
        bottom: 63%;
        font-size: 2vw;
    }
    
    .about-page-title h1 {
        text-align: center;
    }

    .about-page-title p {
        font-size: 1vw;
        width: 100%;
    }

    .about-aim-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .about-aim-title {
        width: 100%;
    }
    
    .about-aim-title p{
        width: 50%;
    }

    .about-data {
        display: flex;
        flex-direction: column-reverse;
    }

    .about-data-txt {
        margin-top: 10%;
        width: 80%;
    }

    .about-data p {
        font-size: 1vw;
        word-spacing: 0.3vw;
        text-align: justify;
    }

    .about-data-img {
        width: 100%;
    }

    .about-aim-title h5, .about-data-title h5 {
        font-size: 2vw;
    }

    .about-aim-title p, .about-data p {
        font-size: 2vw;
        text-align: justify;

    }

}
.call-container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background-color: rgb(255, 255, 255);
    height: 60vh;
    width: 100%;
    margin-bottom: 5%;
}

.call-box-container {
    display: flex;
    flex-direction: row;
    background-color: black;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
    border-radius: 1rem;
    height: 40vh;
    width: 60vw;
    gap: 5rem;
    padding: 5%;
    align-items: center;
    justify-content: center;

    color: white;
}

.call-box-left-img img {
    width: 90%;
    border-radius: 0px 80px 0px 80px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.5);
    object-fit: contain;
}

.call-box-right-text {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    gap: 3rem;
}

.call-box-right-email p {
    letter-spacing: 1px;
}

.call-box-right-call p {
    letter-spacing: 0.5px;
}

.call-box-right-text h4 {
    font-size: 2vw;
    margin: 2% 0%;
    text-transform: uppercase;
    letter-spacing: 0.2vw;
}

.call-box-right-text p {
    font-size: 0.7vw;
    text-transform: uppercase;
}


@media screen and (max-width: 950px) {
    .call-container {
        height: 20vh;
        margin-bottom: 5%;
    }

    .call-box-container {
        height: 20vh;
        width: 50vw;
        gap: 0rem;
    }

    .call-box-left-img img {
        display: none;
        visibility: hidden;
    }

    .call-box-right-text {
        flex-direction: column;
        gap: 1.5rem;
    }

    .call-box-right-text h4 {
        font-size: 3vw;
    }

    .call-box-right-text p {
        font-size: 2.4vw;
        letter-spacing: 0.3vw;
    }
}
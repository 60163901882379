html {
    scroll-behavior: smooth;
}

.mission-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ECE7E5;
    gap: 0.75rem;
    height: auto;
    margin: 0;
    padding: 0;
    width: 100%;
}

.mission-title {
    font-size: 1.25vw;
    margin-top: 3.125rem;
    text-align: center;
}

.mission-data-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    grid-column-gap: 1.5em;
    grid-row-gap: 3em;
    border-radius: 0.9375rem;
    margin: 5% 10%;
    padding: 0.8% 5%;
    width: 80%; 
}

.mission-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 0.9375rem;
    text-align: center;
    cursor: pointer;
    filter: grayscale(0%);
    transition: 0.01s all ease-in-out;
    position: relative;
    overflow: hidden;
}

.mission-img {
    width: 100%;
    overflow: hidden; /* Hide overflow */
    border-radius: 15px;
}

.mission-img img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    cursor: pointer;
    transition: 0.01s all ease-in-out;
}

.mission-bio {
    opacity: 0;
    position: absolute;
    top: 0;
    left:0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    width: 100%; 
    height: 100%; 
    border-radius: 0.9375rem; 
    transition: opacity 0.3s ease-in-out;
    backface-visibility: hidden;
}

.mission-bio h1, .mission-bio p {
    z-index: 1;
    margin: 0.3125rem;
    text-align: center;
}

.mission-data:hover .mission-img img {
    transform: scale(1.05);
}

.mission-data:hover .mission-bio {
    opacity: 1;
}

.mission-data:hover {
    filter: grayscale(100%);
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
    .mission-data-container {
        grid-template-columns: repeat(2, 1fr); /* Two columns for smaller screens */
    }
    
    .mission-bio {
        font-size: 0.8rem; 
    }
}

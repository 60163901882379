
.brands-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffff;
  text-align: center;
  height:auto;
  width:100%;
}

.brands-title {
  padding: 20px;
  font-size: 1.25vw;
}


.brands-logos {
  margin: 0% 10% 2% 10%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width:100%;
}  


.brands-img{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.brands-img img {
  width: 200px;
  height: 120px;
  object-fit: contain;
}

.brands-logos::-webkit-scrollbar {
  display: none; /* Hide the scrollbar */
}

@media screen and (max-width: 430px){
  .brands-title {
    font-size: 3vw;
  }
}
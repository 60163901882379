.cta-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background-color: black;
    color: white;
    margin: 0% 25% 2% 25%;
    padding: 3%;
    border-radius: 1rem;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
}

.cta-text{
    display: flex;
    flex-direction: column;
}


.cta-text h4 {
    font-size: 2vw;
    margin: 2% 0%;
    text-transform: uppercase;
    letter-spacing: 0.2vw;
    
}

.cta-text p {
    font-size: 0.7vw;
    letter-spacing: 0.2vw;
    text-transform: uppercase;
}

.cta-input-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin: 2%;
}


.cta-input-email input{
    box-sizing: border-box;
    margin-bottom: 7%;
}

.cta-input-submit button{
    background-color: white;
    color: black;
    border: 2px solid white;
    border-radius: 0.2rem;
    margin-left: 1rem;
    padding: 0.5rem 1rem;
    font-size: 1vw;
    letter-spacing: 0.2vw;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.3s ease;
}

.cta-input-submit button:hover{
    background-color: #f0f0f0;
    color: black;
    border-color: #f0f0f0;
}

.cta-input-email input, .cta-input-submit button {
    box-sizing: border-box;
    height: 40px;
}